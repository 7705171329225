import React from "react";
import { string, bool, func } from "prop-types";
import { colors } from "../../../style-vars";
import playPrimary from "../../../images/svg/play-primary.svg";
import playSecondary from "../../../images/svg/play-secondary.svg";

const ButtonCircle = ({ className, secondary, onClick }) => {
  if (secondary) {
    return (
      <>
        <button
          type="button"
          onClick={onClick}
          className={`
          ${className}
          circle-btn flex justify-center items-center
          w-14 h-14 bg-white hover:bg-black-800
          rounded-full
          border-black border-2
          cursor-pointer 
          transition-colors
        `}
        >
          <img alt="play button" className="ml-1.5" src={playSecondary} />
        </button>
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className={`
          ${className}
          circle-btn flex justify-center items-center
          w-14 h-14 bg-primary-30 rounded-full
          border-transparent hover:border-primary
          cursor-pointer 
          transition-colors
        `}
      >
        <img alt="play button" className="ml-1.5" src={playPrimary} />
      </button>

      <style jsx>{`
        .circle-btn {
          border-color: transparent;
        }
        .circle-btn:hover {
          border-color: ${colors.primary};
        }
      `}</style>
    </>
  );
};

ButtonCircle.propTypes = {
  secondary: bool,
  className: string,
  onClick: func,
};

ButtonCircle.defaultProps = {
  secondary: false,
  className: "",
  onClick: () => true,
};

export default ButtonCircle;
