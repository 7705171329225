import React, { useRef, useState } from "react";
import { string, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import ButtonCircle from "../../../Base/ButtonCircle";
import Title from "../../../Base/Title";
import background from "../../../../images/svg/vertical-half-circle-left.svg";
import useStartMotion from "../../../../hooks/use-start-motion";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";

const variants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
    },
};

const VideoHeroBasic = ({
    className,
    title,
    subtext,
    poster,
    backgroundImageNameMobile,
    video,
    videoType,
    animations,
}) => {
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(true);
    const [paused, setPaused] = useState(false);
    const { ref, controls } = useStartMotion();

    const onVideoClick = () => {
        const vidRef = videoRef.current;
        setPaused(!paused);

        if (paused) {
            setPlaying(true);
            vidRef.play();
        } else {
            vidRef.pause();
            setPlaying(false);
        }
    };

    return (
        <motion.section
            className={`
      relative
      w-full
      ${className}
    `}
            ref={ref}
            animate={controls}
            initial={isMobileOnly || !animations ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 0.8 }}
        >
            <img
                src={background}
                alt="background"
                className="hidden md:block md:visible md:absolute right-0 -top-32"
            />

            <div className="max-w-1440 w-full my-0 mx-auto px-0 md:px-4 lg:px-10 relative">
                <div className="rounded-large overflow-hidden">
                    <AppetizeImage
                        className="md:hidden"
                        imageName={backgroundImageNameMobile}
                    />
                    {/* <img */}
                    {/*  className="video-poster md:hidden object-cover object-center" */}
                    {/*  src={poster} */}
                    {/*  alt="poster" */}
                    {/* /> */}
                </div>

                <video
                    className="hidden md:block hover rounded-large w-full"
                    ref={videoRef}
                    id="video-hero-basic"
                    // poster={poster}
                    onClick={onVideoClick}
                    loop
                    autoPlay
                    muted
                >
                    <source src={video} type={videoType} />
                </video>

                {!playing && (
                    <ButtonCircle
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden md:block"
                        onClick={onVideoClick}
                    />
                )}

                <Title
                    className="absolute left-4 bottom-11 md:left-10 lg:left-28 lg:bottom-16 text-white"
                    level={2}
                    withBlueSymbol
                >
                    {title}
                </Title>

                <div
                    className={`
          absolute transform -rotate-90
          text-white text-xs
          top-20 right-4 
          md:top-auto md:bottom-36 md:right-20
        `}
                >
                    {subtext}
                </div>
            </div>

            <style jsx>{`
        img.video-poster {
          max-height: 500px;
          transform: translateX(-25%);
        }
      `}</style>
        </motion.section>
    );
};

VideoHeroBasic.propTypes = {
    className: string,
    title: string,
    subtext: string,
    poster: string,
    backgroundImageNameMobile: string.isRequired,
    video: string.isRequired,
    videoType: string.isRequired,
    animations: bool,
};

VideoHeroBasic.defaultProps = {
    className: "",
    title: "",
    subtext: "",
    poster: null,
    animations: true,
};

export default VideoHeroBasic;
